<template lang="pug">
  v-card.d-flex.flex-column(flat :class="$vuetify.breakpoint.lgAndUp ? 'calc' : 'fixed-calc'")
    div(:class="{ curtain: cashRegister.status === 'DEREGISTERED' }")
    v-card.text-right.d-flex.px-2(flat style="flex: 1")
      div.d-flex.flex-row-reverse(flat style="flex: 1; align-self: center")
        v-btn.ml-2(icon @click="toDetails(true)")
          v-icon mdi-barcode-scan
        v-btn(text rounded @click="toJournal()") {{ $t("смена") }}
        v-btn(text rounded @click="toDetails(false)") {{ $t("позиции") }}
        v-btn(v-if="!cashPayment" text rounded disabled color="warning" style="color: #fb8c00 !important; caret-color: #fb8c00 !important;") {{ $t("карта") }}
        v-btn(icon color="warning" @click="showAlertMessage()" v-if="alertMessage")
          v-icon mdi-alert
    v-card#displayCard.text-right.display-4.d-flex.px-4.calc-container(flat style="flex: 3; overflow-y: auto;")
      div.noselect.calc-display(flat style="flex: 1; align-self: center; white-space: pre-line; max-height: 100%;") {{ display }}
    div.text-center(v-if="cashRegister.status === 'TRIAL'" style="position: relative; top: -15px;")
      a(@click.prevent="$openLink('https://link.rekassa.kz/ttk-registration-and-activation', '_system')") &nbsp; {{ $t("как_зарегистрировать_кассу") }}
    div.d-flex(style="flex: 8" class="numpad")
      div.d-flex.flex-column(style="flex: 3")
        div.d-flex(style="flex: 1")
          div(style="flex: 1; display: flex;")
            v-btn.pa-0.headline(depressed @click="digit('7')") 7
          div(style="flex: 1; display: flex;")
            v-btn.pa-0.headline(depressed @click="digit('8')") 8
          div(style="flex: 1; display: flex;")
            v-btn.pa-0.headline(depressed @click="digit('9')") 9
        div.d-flex(style="flex: 1")
          div(style="flex: 1; display: flex;")
            v-btn.pa-0.headline(depressed @click="digit('4')") 4
          div(style="flex: 1; display: flex;")
            v-btn.pa-0.headline(depressed @click="digit('5')") 5
          div(style="flex: 1; display: flex;")
            v-btn.pa-0.headline(depressed @click="digit('6')") 6
        div.d-flex(style="flex: 1")
          div(style="flex: 1; display: flex;")
            v-btn.pa-0.headline(depressed @click="digit('1')") 1
          div(style="flex: 1; display: flex;")
            v-btn.pa-0.headline(depressed @click="digit('2')") 2
          div(style="flex: 1; display: flex;")
            v-btn.pa-0.headline(depressed @click="digit('3')") 3
        div.d-flex(style="flex: 1")
          div(style="flex: 1; display: flex;")
            v-btn.pa-0.headline(depressed @click="plus()") &plus;
          div(style="flex: 1; display: flex;")
            v-btn.pa-0.headline(depressed @click="digit('0')") 0
          div(style="flex: 1; display: flex;")
            v-btn.pa-0.headline(depressed @click="backspace()")
              v-icon mdi-backspace
      div.d-flex.flex-column(style="flex: 1")
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline.text-capitalize(style="font-size: 1.4rem !important" depressed color="secondary" @click="toggleCashPayment()") {{ cashPayment ? $t("наличные_short") : $t("карта") }}
        div(style="flex: 3; display: flex;")
          v-btn.pa-0.headline.text-capitalize(style="font-size: 1.4rem !important" depressed color="primary" @click="next()") {{ $t("далее") }}
</template>
<script>
import fdo from 'kfdo'
import { Decimal } from 'decimal.js'
import { mapState, mapActions } from 'vuex'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import billsAndCoinsMixin from '../../mixins/billsAndCoinsMixin'

export default {
  name: 'Calculator',

  mixins: [dictionaryMixin, billsAndCoinsMixin],

  data: () => ({
    cashPayment: localStorage.getItem('rekassa.kz-ui-defaultPaymentType') === null || localStorage.getItem('rekassa.kz-ui-defaultPaymentType') === 'PAYMENT_CASH',
    stack: [''],
    index: 0,
    display: '0',
    total: 0,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
      keyListenerEnabled: state => state.tools.keyListenerEnabled,
    }),

    shift() {
      return this.cashRegister ? this.cashRegister.shift : null
    },

    shiftTicketNumber() {
      if (this.cashRegister && this.shift && this.shift.open && this.shift.shiftTicketNumber > 0) {
        return this.shift.shiftTicketNumber
      }
      return null
    },

    shiftNumber() {
      if (this.shift && this.shift.open && this.shift.shiftNumber) {
        return this.shift.shiftNumber
      }
      return null
    },

    alertMessage() {
      if (this.cashRegister && this.cashRegister.data && this.cashRegister.data.service && this.cashRegister.data.service.ticketAds) {
        const ticketAd = this.cashRegister.data.service.ticketAds.find((item) => item.info.type === 'TICKET_AD_INFO')
        if (ticketAd) {
          return ticketAd.text
        }
        return null
      }
      return null
    },
  },

  mounted() {
    this.setTicketRequest(null)
    window.addEventListener('keydown', this.keyListener, false)
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.keyListener, false)
  },

  methods: {
    ...mapActions({
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      setTicketRequest: 'cashRegisters/setTicketRequest',
    }),

    formatThousends(x) {
      const parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return parts.join('.')
    },

    keyListener(event) {
      if (this.keyListenerEnabled && (this.cashRegister.status === 'REGISTERED' || this.cashRegister.status === 'TRIAL')) {
        const reg = new RegExp('^[0-9\\.]$')
        if (reg.test(event.key)) {
          this.digit(event.key)
        } else if (event.key === 'Backspace') {
          this.backspace()
        } else if (event.key === 'Enter') {
          this.next()
        } else if (event.key === '+') {
          this.plus()
        }
      }
    },

    showAlertMessage() {
      this.showConfirm({
        title: this.$t('информационное_сообщение'),
        text: this.alertMessage,
      }).then(() => {}).catch(() => {})
    },

    toDetails(openScanner) {
      try {
        localStorage.setItem('rekassa.kz-tools-openScanner', openScanner === true ? 'true' : false)
        if (this.total === 0) {
          this.$router.push('/details')
        } else {
          this.prepareTicketRequest()
          this.$router.push('/details')
        }
      } catch (error) {
        this.$router.push('/details')
      }
    },

    toJournal() {
      if (this.shiftNumber) {
        this.$router.push(`/shifts/${this.cashRegister.id}/${this.shiftNumber}`)
      } else {
        this.showSnackbar({ message: this.$t('смена_не_открыта'), urlCode: 'CASH_REGISTER_SHOULD_HAVE_SHIFT_OPEN' })
      }
    },

    toggleCashPayment() {
      this.playClickSound()
      if (this.preferences.paymentTypes.card) {
        this.cashPayment = !this.cashPayment
      }
    },

    digit(digit) {
      this.playClickSound()
      if ((this.stack[this.index] === '' && digit === '0') || (this.stack[this.index] !== '' && this.stack[this.index].length >= 9)) return
      if (this.stack[this.index] === '') {
        this.stack[this.index] = digit
      } else {
        this.stack[this.index] += digit
      }
      this.prepareDisplay()
    },

    backspace() {
      this.playClickSound()
      if (this.stack[this.index] === '' && this.index > 0) {
        this.index -= 1
        this.stack.pop()
      } else {
        this.stack[this.index] = this.stack[this.index].substring(0, this.stack[this.index].length - 1)
      }
      this.prepareDisplay()
    },

    plus() {
      this.playClickSound()
      if (this.stack[this.index] !== '' && this.stack.length <= 49) {
        this.index += 1
        this.stack[this.index] = ''
        this.prepareDisplay()
      }
    },

    prepareDisplay() {
      this.display = this.stack.reduce((all, item) => {
        if (all === '') {
          // eslint-disable-next-line no-irregular-whitespace
          return item !== '' ? `${this.formatThousends(item)}‎ ₸` : '0'
        }
        // eslint-disable-next-line no-irregular-whitespace
        return item !== '' ? `${all} + ${this.formatThousends(item)}‎ ₸` : `${all} + 0`
      }, '')
      this.total = this.stack.reduce((all, item) => (item !== '' ? new Decimal(all).plus(item).toNumber() : all), 0)
      this.$nextTick(() => {
        const el = document.getElementById('displayCard')
        el.scrollTop = el.scrollHeight
      })
    },

    next() {
      this.playClickSound()
      try {
        if (this.total === 0) {
          this.showSnackbar({ message: this.$t('введите_сумму'), timeout: 1000 })
          return
        }
        this.prepareTicketRequest()
        this.$router.push('/draft')
      } catch (error) {
        // console.log(`error: ${error}`)
      }
    },

    prepareTicketRequest() {
      const ticketRequest = new fdo.kkm.proto.TicketRequest()

      ticketRequest.operation = fdo.kkm.proto.OperationTypeEnum.OPERATION_SELL
      ticketRequest.domain = new fdo.kkm.proto.TicketRequest.Domain({ type: this.dictionary.domainType[this.preferences.domainType].code })

      this.stack.forEach((item) => {
        if (item !== '') {
          ticketRequest.items.push(this.prepareItem(new Decimal(item).toNumber()))
        }
      })

      const payment = new fdo.kkm.proto.TicketRequest.Payment({
        type: this.cashPayment ? fdo.kkm.proto.PaymentTypeEnum.PAYMENT_CASH : fdo.kkm.proto.PaymentTypeEnum.PAYMENT_CARD,
        sum: this.getBillsAndCoinsMoney(this.total),
      })
      ticketRequest.payments.push(payment)

      ticketRequest.amounts = new fdo.kkm.proto.TicketRequest.Amounts({
        total: this.getBillsAndCoinsMoney(this.total),
        taken: this.getBillsAndCoinsMoney(this.cashPayment ? this.total : 0),
        change: this.getBillsAndCoinsMoney(0),
      })

      this.setTicketRequest(ticketRequest)
    },

    prepareItem(sum) {
      const item = new fdo.kkm.proto.TicketRequest.Item({
        type: fdo.kkm.proto.TicketRequest.Item.ItemTypeEnum.ITEM_TYPE_COMMODITY,
        commodity: new fdo.kkm.proto.TicketRequest.Item.Commodity({
          name: this.preferences.defaultItemName,
          sectionCode: '1',
          quantity: 1000,
          price: this.getBillsAndCoinsMoney(sum),
          sum: this.getBillsAndCoinsMoney(sum),
          auxiliary: [
            new fdo.kkm.proto.KeyValuePair({
              key: 'UNIT_TYPE',
              value: this.preferences.defaultItemType,
            }),
          ],
        }),
      })

      if (this.configuration.taxMode && this.dictionary.taxType[this.preferences.defaultTaxType].rate >= 0) {
        item.commodity.taxes = [
          new fdo.kkm.proto.TicketRequest.Tax({
            taxType: 100,
            taxationType: this.dictionary.taxationType[this.configuration.taxationType].code,
            percent: new Decimal(this.dictionary.taxType[this.preferences.defaultTaxType].rate).times(1000).toNumber(),
            sum: this.getBillsAndCoinsMoney(new Decimal(sum)
              .dividedBy(new Decimal(this.dictionary.taxType[this.preferences.defaultTaxType].rate).add(100))
              .times(this.dictionary.taxType[this.preferences.defaultTaxType].rate)
              .toNumber()
              .toFixed(2)),
            isInTotalSum: true,
          }),
        ]
        item.commodity.taxes[0].sum.value = this.commodityTax
      }

      return item
    },

    playClickSound() {
      if (this.$isCordova()) {
        window.nativeclick.trigger()
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.fixed-calc
  height calc(100% - 48px)
  width 100%
  position fixed
  bottom 0
  right 0
.calc
  height calc(100vh - 48px)
  width 100%
  margin: 0 70px
.calc-display
  font-size calc(3.5vw + 3.5vh + 2vmin)
  line-height calc(3.5vw + 3.5vh + 2vmin)
  letter-spacing initial !important
  font-family "Eczar", sans-serif !important
  white-space normal !important
.numpad .v-btn
  height auto
  width 100%
.curtain
  position absolute
  width 100%
  height 100%
  z-index 1
  background rgba(0,0,0,0.4)
</style>
